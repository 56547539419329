$(window).load(function () {
  $(".no-js-link").each(function() {
    var contents = $(this).html();
    var attrString = " ";
    $.each(this.attributes, function(i, attrib){
      if (attrib.name !== "href") {
        if (attrib.name === "class") {
          var classNames = attrib.value.split(" ");
          classNames = classNames.filter(function(className) { return className !== "no-js-link"; });
          attrString += attrib.name + "='" + classNames.join(" ") + "' ";
        } else {
          attrString += attrib.name + "='" + attrib.value + "' ";
        }
      }
    });
    $("<div" + attrString + "></div>").append(contents).replaceAll($(this));
  });
});
